<script setup lang="ts">
const { canView } = useACL();

const menuShown = ref(false);

const toggleMenu = () => {
  menuShown.value = !menuShown.value;
};

const closeMenu = () => {
  menuShown.value = false;
};
</script>

<template>
  <aside>
    <button class="fixed top-0 left-0 z-[99] p-1" @click.prevent="toggleMenu">
      <Icon name="material-symbols:menu" size="3em" />
    </button>
    <div
      class="fixed top-0 left-0 w-full h-full bg-black/50 z-30"
      :class="{
        hidden: !menuShown,
      }"
      @click.prevent="toggleMenu"
    />
    <div
      class="md:flex fixed top-0 left-0 h-screen w-32 flex-col justify-between bg-background-500 dark:bg-background-200 shadow-lg z-50 z-[100]"
      :class="{
        hidden: !menuShown,
      }"
    >
      <div>
        <NuxtLink to="/dashboard">
          <NuxtImg
            :src="
              $colorMode.value === 'light'
                ? '/goodielogo.jpg'
                : '/goodielogodark.jpg'
            "
            class="mb-12"
            @click="closeMenu"
          />
        </NuxtLink>
        <NuxtLink to="/dashboard" @click="closeMenu">
          <SidebarIcon
            icon-name="material-symbols:dashboard"
            tooltip="Dashboard"
          />
        </NuxtLink>
        <NuxtLink v-if="canView('jobsites')" to="/jobsites" @click="closeMenu">
          <SidebarIcon icon-name="bxs:hard-hat" tooltip="Job Sites" />
        </NuxtLink>
        <NuxtLink v-if="canView('calendar')" to="/calendar">
          <SidebarIcon
            icon-name="material-symbols:calendar-month"
            tooltip="Calendar"
          />
        </NuxtLink>
        <NuxtLink
          v-if="canView('equipment')"
          to="/equipment"
          @click="closeMenu"
        >
          <SidebarIcon icon-name="mdi:bulldozer" tooltip="Equipment" />
        </NuxtLink>
        <NuxtLink
          v-if="canView('map')"
          to="/maps/jobsites-and-vehicles"
          @click="closeMenu"
        >
          <SidebarIcon
            icon-name="material-symbols:location-on"
            tooltip="Maps"
          />
        </NuxtLink>
        <NuxtLink to="/workorders" @click="closeMenu">
          <SidebarIcon
            icon-name="mdi:format-list-checks"
            tooltip="All Work Orders"
          />
        </NuxtLink>
        <NuxtLink
          v-if="canView('inspections')"
          to="/inspections"
          @click="closeMenu"
        >
          <SidebarIcon icon-name="octicon:checklist-16" tooltip="Inspections" />
        </NuxtLink>
        <NuxtLink
          v-if="canView('workorders_quick_create')"
          to="/workorders/create"
          @click="closeMenu"
        >
          <SidebarIcon
            icon-name="mdi:clipboard-plus-outline"
            tooltip="Create Work Order"
          />
        </NuxtLink>
        <NuxtLink
          v-if="canView('inspections_quick_create')"
          to="/inspections/create"
          @click="closeMenu"
        >
          <SidebarIcon
            icon-name="material-symbols:library-add-check-outline"
            tooltip="Create Inspection"
          />
        </NuxtLink>
      </div>
      <div class="sidebar-footer">
        <SidebarIcon :theme-toggle="true" tooltip="Dark/Light mode" />
        <NuxtLink to="/settings" @click="closeMenu">
          <SidebarIcon icon-name="mdi:cog" tooltip="Settings" />
        </NuxtLink>
        <SidebarIcon icon-name="mdi:logout" tooltip="Logout" @click="logout" />
      </div>
    </div>
  </aside>
</template>
