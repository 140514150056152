import * as Sentry from "@sentry/vue";
import type { Database } from "~/types/supabase";

export default async () => {
  const supabase = useSupabaseClient<Database>();

  try {
    const { error } = await supabase.auth.signOut();

    if (error) throw error;

    navigateTo("/auth");
  } catch (error) {
    Sentry.captureException(error);

    alert("There was an error signing out. Please try again.");
  }
};
